.programma_body {
	color: rgb(4, 54, 4);
	font-family: "Alice", serif;
	letter-spacing: 0.05em;
	font-size: 16px;
}
.programma_header {
	font-family: "Cormorant Garamond", serif;
	font-size: 112px;
	font-weight: 600;
	color: rgb(2, 41, 2);
	margin: 0px;
	margin-top: 20px;
	letter-spacing: 0.1em;
	z-index: 2;
	opacity: 0.5;
	position: relative;
	text-transform: uppercase;
}
.programma_header-container {
	position: fixed;
	right: 20px;
	top: -10px;
	display: none;
}
.programma_header-background {
	height: 80%;
	background-color: #fffaeb;
	opacity: 100%;
	position: absolute;
	top: 0px;
	right: 0px;
	z-index: 1;
	width: 100vw;
}
.programma_header-background::after {
	content: "";

	width: 100%;
	height: 100px;
	position: absolute;
	bottom: -100px;
	right: 0;
	background: rgb(255, 250, 235);
	background: linear-gradient(
		180deg,
		rgba(255, 250, 235, 1) 19%,
		rgba(255, 250, 235, 0) 100%
	);
}
.programma_grid {
	display: grid;
	grid-template-columns: 1fr 4fr;
	column-gap: 40px;
	/* row-gap: 10px; */
	margin-bottom: 60px;
	max-width: 100%;
}
.programma_grid-time {
	text-align: right;
}
.programma_grid-item:last-child {
	padding-bottom: 5px;
}
.programma_grid-item {
	border-left: 1px solid rgb(4, 54, 4);
	padding: 0px 30px 40px 40px;
	text-align: left;
	white-space: nowrap;
	display: grid;
	grid-template-columns: 50px auto;
	grid-template-rows: auto auto;
	align-items: center;
	font-size: 18px;
}
.programma_grid-icon {
	margin-right: 20px;
}
.programma_grid-subtitle {
	grid-column: 2/3;
	margin-top: 10px;
	color: rgba(0, 0, 0, 0.582);
	font-size: 0.85em;
	white-space: normal;
}
.programma_paragraph {
	text-align: left;
}

@media (min-width: 600px) {
	.programma_header-background {
		width: 67vw;
	}
	.programma_header-container {
		display: block;
	}
}
@media (min-width: 900px) {
	.programma_header-background {
		width: 42vw;
	}
}
@media (min-width: 1200px) {
	.programma_header-background {
		width: 33vw;
	}
}
