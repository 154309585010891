.home_heading-names {
	font-family: "Playfair", serif;
	font-size: 36px;
	font-weight: 500;
	color: rgb(4, 54, 4);
	margin: 70px 0px 70px 0px;
	white-space: nowrap;
}

.home_heading-names-robin,
.home_heading-names-iris {
	display: inline-block;
	text-transform: uppercase;
}
.home_heading-names-robin {
	transform: translateY(20px);
	margin-left: 40px;
}
.home_heading-names-iris {
	transform: translateY(-20px);
	margin-right: 40px;
}
.home_heading-names-and {
	font-family: "Cinzel Decorative";
}
.home_heading-date span {
	display: block;
	transform: translateY(-5px);
	margin: 0 5px;
}
.home_heading-date,
.home_heading-time {
	font-family: "Cormorant Garamond", serif;
	font-size: 40px;
	font-weight: 500;
	color: rgb(4, 54, 4);
	white-space: nowrap;
	margin: 0;
	line-height: 1em;
}

.home_heading-date {
	display: flex;
	justify-content: center;
	margin-bottom: 50px;
}
.home_heading-time {
	margin-bottom: 70px;
	font-size: 40px;
}

.home_heading-location {
	font-family: "Cinzel Decorative", serif;
	font-size: 24px;
	font-weight: 500;
	color: rgb(4, 54, 4);
	margin: 0px 0px 40px 0px;
	letter-spacing: 0.1em;
}

.home_heading-h4 {
	font-family: "Cormorant Garamond", serif;
	font-size: 24px;
	font-weight: 500;
	color: rgb(4, 54, 4);
	margin: 0px 0px 40px 0px;
}

.home_paragraph {
	line-height: 1.5em;
}
.home_link {
	display: inline-block;

	color: inherit; /* blue colors for links too */
	text-decoration: inherit; /* no underline */
}

@media (min-width: 600px) {
	.home_heading-names {
		font-size: 46px;
	}
}
@media (min-width: 900px) {
}
@media (min-width: 1200px) {
}
