@import url("https://api.mapbox.com/mapbox-gl-js/v2.14.1/mapbox-gl.css");
@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@300&family=Montserrat:wght@300;400&display=swap");

.map-container {
	height: 300px;
	overflow: hidden;
	border-radius: 10px;
}
.mapboxgl-popup-content {
	padding: 7px;
	font-family: "Montserrat", sans-serif;
}
.mapboxgl-popup-content a {
	color: blue;
	text-decoration: inherit;
}

.mapbox-popup-header {
	display: block;
	margin: 0px;
	font-weight: bold;
	font-size: 13px;
}
.mapboxgl-popup-content {
}
.mapboxgl-popup-content p {
	padding: 0px;
	margin: 0px;
	line-height: 13px;
}
