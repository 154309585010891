@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@300&family=Montserrat:wght@300;400&display=swap");

.menu_container {
	font-family: "Cormorant Garamond", serif;
	letter-spacing: 2px;
}
.menuButton {
	background-color: rgba(255, 255, 255, 0.717);
	width: 130px;
	height: 50px;
	position: fixed;
	border-radius: 200px;
	left: 20px;
	top: 30px;
	box-shadow: 6px 6px 10px rgba(0, 0, 0, 0.136);
	display: flex;
	align-items: center;
	justify-content: space-around;
	z-index: 997;
	cursor: pointer;
	padding: 0px 20px;
	color: rgb(70, 70, 70);
}
@media (max-width: 600px) {
	.menuButton {
		width: 40px;
		top: 20px;
		left: auto;
		right: 20px;
	}

	.menuButton-text {
		display: none;
	}
}
.menuButton-text {
	text-transform: uppercase;
	padding-right: 5px;
	font-family: "Montserrat", sans-serif;
}

.menu_backdrop {
	position: fixed;
	z-index: 998;
	width: 100vw;
	height: 100vh;
	background-color: rgb(0, 0, 0, 0.15);
	backdrop-filter: blur(2px);
	transition: opacity 1s;
}
.menu_container.showing .menu_backdrop {
	transform: translateX(0vw);
	opacity: 100;
}
.menu_container.hidden .menu_backdrop {
	opacity: 0;
}
.menu_container.removed .menu_backdrop {
	transform: translateX(-100vw);
}

.menu_inner {
	position: fixed;
	display: flex;
	flex-direction: column;
	left: 0;
	top: 0;
	width: 350px;
	height: 100vh;
	background-color: rgb(249, 249, 246);
	flex-grow: 1;
	z-index: 999;
	transition: all 0.5s;
	padding: 20px;
	overflow: scroll;
}
.menu_container.showing .menu_inner {
	transform: translateX(0px);
}
.menu_container.hidden .menu_inner {
	transform: translateX(-350px);
}

.menu_inner-closebutton {
	position: absolute;
	top: 20px;
	right: 20px;
	width: 50px;
	height: 50px;
	display: flex;
	justify-content: space-around;
	align-items: center;
	border-radius: 100%;
	cursor: pointer;
	transition: all 0.3s;
}
.menu_inner-closebutton:hover {
	background-color: rgb(231, 231, 231);
}

.list_container {
	margin: 20px 20px;
	height: 100%;
	width: 100%;
	padding: 50px 20px;
}

.menu_list_item:hover {
	background-color: aquamarine;
}

.menu_list_item span {
	flex-grow: 1;
}

.menu_list a:link,
.menu_list a:visited {
	text-decoration: none;
	color: inherit;
}

.menu_header {
	font-size: 32px;
	text-align: left;
	margin-bottom: 40px;
}

.menu_link-list {
	list-style-type: none;
	text-align: left;
	padding: 0;
	margin-top: 0;
	margin-right: 30px;
	font-size: 20px;
	font-family: "Montserrat", sans-serif;
	display: flex;
	flex-direction: column;
	flex-grow: 1;
}
.menu_link-listitem {
	margin: 10px;
	margin-left: 0px;
	padding: 0px 0px 0px 20px;
	cursor: pointer;
}
.menu_link-listitem a,
.menu_link-listitem span {
	color: inherit;
	text-decoration: inherit;
	display: block;
	padding: 15px 0px;
	height: 100%;
	width: 100%;
}
.menu_link-listitem:hover {
	background-color: rgb(211, 209, 185, 0.3);
	border-radius: 15px;
}
.menu_link-listitem:not(:last-child) {
}
.menu_login-info {
	width: 100%;
	min-height: 150px;
}
.menu_login-logout {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 20px;
	cursor: pointer;
}
.menu_login-logout span {
	margin-right: 20px;
}
.menu_language {
	display: grid;
	grid-template-columns: auto auto;
	margin: 0 auto 50px auto;
	cursor: pointer;
}
.menu_language-header {
	grid-column: 1/3;
	/* padding-bottom: 20px; */
	/* padding-top: 20px; */
	background-color: rgb(4, 54, 4, 0);
	padding: 10px 20px;
	margin-top: 30px;
	margin-bottom: 30px;
	font-size: 16px;
	letter-spacing: 2px;
	font-weight: bold;
	font-family: "Cormorant Garamond", serif;
	cursor: pointer;
	box-shadow: 0px 0px 10px rgb(0, 0, 0, 0.15);
	transition: all 0.2s;
}

.menu_language-header:hover {
	background-color: rgba(244, 243, 235, 0.879);
	transform: translateY(-6px);
	box-shadow: 0px 6px 5px rgb(0, 0, 0, 0.25);
}
.menu_language-header:active {
	box-shadow: 0px 3px 5px rgb(0, 0, 0, 0.25);
	transform: translateY(-3px);
}
