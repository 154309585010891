.parkeren_header {
	font-family: "Cormorant Garamond", serif;
	font-size: 112px;
	font-weight: 600;
	color: rgb(2, 41, 2);
	margin: 0px;
	margin-top: 20px;
	letter-spacing: 0.1em;
	z-index: 2;
	opacity: 0.5;
	position: relative;
	text-transform: uppercase;
}
.parkeren_header-container {
	position: fixed;
	right: 20px;
	top: -10px;
	display: none;
}
.parkeren_header-background {
	height: 80%;
	background-color: #fffaeb;
	opacity: 100%;
	position: absolute;
	top: 0px;
	right: 0px;
	z-index: 1;
	width: 100vw;
}
.parkeren_header-background::after {
	content: "";

	width: 100%;
	height: 100px;
	position: absolute;
	bottom: -100px;
	right: 0;
	background: rgb(255, 250, 235);
	background: linear-gradient(
		180deg,
		rgba(255, 250, 235, 1) 19%,
		rgba(255, 250, 235, 0) 100%
	);
}
.parkeren_paragraph {
	text-align: center;
	line-height: 1.5em;
}
.parkeren_body {
	color: rgb(4, 54, 4);
	font-family: "Alice", serif;
	letter-spacing: 0.05em;
	font-size: 16px;
}

@media (min-width: 600px) {
	.parkeren_header-background {
		width: 67vw;
	}
	.parkeren_header-container {
		display: block;
	}
}
@media (min-width: 900px) {
	.parkeren_header-background {
		width: 42vw;
	}
}
@media (min-width: 1200px) {
	.parkeren_header-background {
		width: 33vw;
	}
}
