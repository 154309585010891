.rsvp_form {
	box-sizing: border-box;
	margin: 0 auto;
	width: 400px;
	max-width: 100vw;
	padding: 0px 10px 0px 10px;
}
.rsvp_header {
	font-family: "Cormorant Garamond", serif;
	font-size: 192px;
	font-weight: 600;
	color: rgb(2, 41, 2);
	margin: 0px;
	margin-top: 20px;
	letter-spacing: 0.1em;
	z-index: 999;
	opacity: 0.5;
	position: relative;
}
.rsvp_header-container {
	position: fixed;
	right: 20px;
	top: -10px;
	display: none;
}
.rsvp_header-background {
	height: 80%;
	background-color: #fffaeb;
	opacity: 100%;
	width: 90%;
	position: absolute;
	top: 0px;
	right: 0px;
	z-index: 1;
}
.rsvp_header-background::after {
	content: "";

	width: 100%;
	height: 100px;
	position: absolute;
	bottom: -100px;
	right: 0;
	background: rgb(255, 250, 235);
	background: linear-gradient(
		180deg,
		rgba(255, 250, 235, 1) 19%,
		rgba(255, 250, 235, 0) 100%
	);
}
.rsvp_small-header {
	font-family: "Cormorant Garamond", serif;
	font-size: 36px;
	font-weight: 600;
}
.rsvp_paragraph {
	font-weight: 400;
	font-size: 18;
}

@media (min-width: 600px) {
	.rsvp_header-background {
		width: 67vw;
	}
	.rsvp_header-container {
		display: block;
	}
}
@media (min-width: 900px) {
	.rsvp_header-background {
		width: 42vw;
	}
}
@media (min-width: 1200px) {
	.rsvp_header-background {
		width: 33vw;
	}
}

@media (max-width: 600px) {
}
