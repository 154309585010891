@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@300&family=Montserrat:wght@300;400&display=swap");

body {
	background-color: #fffaeb;
}

.form {
	margin: 0 auto;
	width: 400px;
}
.header {
	font-family: "Cormorant Garamond", serif;
	font-size: 42px;
	font-weight: 600;
	color: rgb(2, 41, 2);
	margin: 0px;
	margin-top: 20px;
}
.small-header {
	font-family: "Cormorant Garamond", serif;
	font-size: 36px;
	font-weight: 600;
}
.paragraph {
	font-family: "Montserrat", sans-serif;
	font-weight: 400;

	font-size: 18;
}
