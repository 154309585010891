@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@300&family=Montserrat:wght@300;400&display=swap");

.landing-paragraph {
	font-weight: 400;
	line-height: 1.6em;
	font-size: 18;
}

.landing-small-header {
	font-family: "Cormorant Garamond", serif;
	font-size: 30px;
	font-weight: 600;
}

.landing-button {
	background-color: rgb(4, 54, 4, 0);
	padding: 10px 20px;
	text-transform: capitalize;
	margin-top: 30px;
	margin-bottom: 30px;
	font-size: 22px;
	letter-spacing: 2px;
	font-weight: bold;
	font-family: "Cormorant Garamond", serif;
	cursor: pointer;
	box-shadow: 0px 0px 10px rgb(0, 0, 0, 0.15);
	transition: all 0.2s;
}
.landing-button:hover {
	background-color: rgba(244, 243, 235, 0.879);
	transform: translateY(-6px);
	box-shadow: 0px 6px 5px rgb(0, 0, 0, 0.25);
}
.landing-button:active {
	box-shadow: 0px 3px 5px rgb(0, 0, 0, 0.25);
	transform: translateY(-3px);
}

.landing_heading-names {
	font-family: "Playfair", serif;
	font-size: 36px;
	font-weight: 500;
	color: rgb(4, 54, 4);
	margin: 70px 0px 70px 0px;
	white-space: nowrap;
}

.landing_heading-names-robin,
.landing_heading-names-iris {
	display: inline-block;
	text-transform: uppercase;
}
.landing_heading-names-robin {
	transform: translateY(20px);
	margin-left: 40px;
}
.landing_heading-names-iris {
	transform: translateY(-20px);
	margin-right: 40px;
}
.landing_heading-names-and {
	font-family: "Cinzel Decorative";
}
.landing_heading-date span {
	display: block;
	transform: translateY(-5px);
	margin: 0 5px;
}
.landing_heading-date,
.landing_heading-time {
	font-family: "Cormorant Garamond", serif;
	font-size: 40px;
	font-weight: 500;
	color: rgb(4, 54, 4);
	white-space: nowrap;
	margin: 0;
	line-height: 1em;
}

.landing_heading-date {
	display: flex;
	justify-content: center;
	margin-bottom: 50px;
}
.landing_heading-time {
	margin-bottom: 70px;
	font-size: 40px;
}

@media (min-width: 600px) {
	.landing_heading-names {
		font-size: 46px;
	}
}
