/* @import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@300&family=Montserrat:wght@300;400&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Alice&family=Cinzel+Decorative:wght@400;700&family=Cormorant+Garamond:wght@300;500;600;700&family=Montserrat:wght@300;400&family=Playfair:wght@300;400;500;600;700;800;900&display=swap");
/* @import url("https://fonts.googleapis.com/css2?family=Alice&family=Cormorant+Garamond:wght@300;500;600;700&family=Montserrat:wght@300;400&family=Playfair:wght@300;400;500;600;700;800;900&display=swap"); */
@import url("./screens/guest/HomeScreen.css");
@import url("./screens/guest/RsvpScreen.css");
@import url("./screens/guest/LocatieScreen.css");
@import url("./screens/guest/ProgrammaScreen.css");
@import url("./screens/guest/ParkerenScreen.css");
@import url("./screens/guest/OvernachtenScreen.css");
@import url("./screens/guest/SpeechesScreen.css");
@import url("./screens/guest/CadeauScreen.css");
@import url("./screens/guest/ContactScreen.css");
@import url("./screens/guest/DresscodeScreen.css");

.App {
	text-align: center;
}

body {
	text-align: center;

	font-family: "Alice", serif;
	color: #012001;
	background-color: #fffaeb;
}

.paragraph {
	font-family: "Montserrat", sans-serif;
	font-weight: 400;
	line-height: 1.5em;
	font-size: 18;
}

p {
	line-height: 1.5em;
}

a {
	text-decoration: none;
	color: inherit;
}

.paragraph a {
	text-decoration: none;
	color: inherit;
	font-weight: bold;
	text-align: left;
}
.paragraph .bold {
	font-weight: 600;
}

.header {
	font-family: "Cormorant Garamond", serif;
	font-size: 36px;
	font-weight: 700;
	color: rgb(4, 54, 4);
}

.small-header {
	font-family: "Cormorant Garamond", serif;
	font-size: 30px;
	font-weight: 600;
}
.small-header:not(:first-of-type) {
	margin-top: 100px;
}
.small-header4 {
	font-family: "Cormorant Garamond", serif;
	font-size: 22px;
	font-weight: 500;
	margin-bottom: 0px;
}

.text-emphasis {
	font-family: "Montserrat", sans-serif;
	font-size: 18px;
	font-weight: 600;
}

.information-detail {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-left: 20px;
}
.information-detail:not(:last-child) {
	margin-bottom: 15px;
}
.information-detail a {
	text-decoration: inherit;
	color: inherit;
	margin-left: 20px;
	text-align: left;
}

#imagebox::after {
	height: 100%;
	width: 100%;
	position: absolute;
	content: "";
	left: 0;
	bottom: 0;
	/* z-index: -1; */
	box-shadow: inset 0px -20px 15px rgb(255 250 235);
}
@media (min-width: 600px) {
	#imagebox::after {
		height: 110%;
		width: 100%;
		position: absolute;
		content: "";
		right: 0;
		top: 0;
		/* z-index: -1; */
		box-shadow: inset -20px -10px 15px rgb(255 250 235);
	}
}
